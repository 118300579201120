import { actionPromise } from "../store"
import { gql } from "../../helpers"

export const actionAllProject = () => 
  async dispatch => {

   await dispatch(actionPromise('allProject', gql(
		`
			query getProjects {
				projects{
					id
					headline
					icon
					introduce
					newProject
					technologies
					mainImageId{
						id
						imageId{
							id
							link
						}
					}
				}
		  	}
		`, 
		{}
	)))
   

  }

  export const actionSpecificProject = (id) => 
  async dispatch => {

   await dispatch(actionPromise('specificProject', gql(
		`
			query getProjects {
				project(id:${id}){
					id
					headline
					icon
					introduce
					newProject
					technologies
					mainImageId{
						id
						imageId{
							id
							link
						}
					}
					projectimagesSet{
						id
						imageId{
							id
							link
						}
					}
					colorsprojectSet{
						id
						colorId{
							id
							hexCode
							name
						}
					}
					descriptionproject{
						id
						readingTime
						text
						link 
					}
					technologiesproject{
						id
						readingTime
						text
						link 
					}
					codeproject{
						id
						readingTime
						text
						link 
					}
				}
		  	}
		`, 
		{}
	)))
   

  }