import { memo, useState } from "react";
import { Link, NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MenuLink = memo(({name, icon, link, onClick}) => {

    return(
        <NavLink to={link}  className={(navData) => (navData.isActive ? "menuLinksActive" : '')} onClick={onClick}>  
            <FontAwesomeIcon icon={icon} />
            <div>{name}</div>
        </NavLink>
    )
})