import { memo, useEffect } from "react"
import cloud from '../../img/cloud.png'
import cloud2 from '../../img/cloud2.png'
import cloud3 from '../../img/cloud3.png'
import ufo from '../../img/UFO.png'

const clouds = [
    {
        img:cloud,
        className: 'cloud_1',
    },
    {
        img:cloud,
        className: 'cloud_2',
    },
    {
        img:cloud2,
        className: 'cloud_3',
    },
    {
        img:cloud3,
        className: 'cloud_4',
    },
]

const ufos = [
    {
        img:ufo,
        className: 'ufo_1'
    },
    {
        img:ufo,
        className: 'ufo_2'
    },
    {
        img:ufo,
        className: 'ufo_3'
    },
]

export const Alien = memo(({}) => {
    useEffect(()=>{
        if (!/Mobi/.test(navigator.userAgent)){ 
            document.getElementById('MainPage').addEventListener('mousemove', e => {
                let x, y
                
                if (e.pageX)
                {
                    x = e.pageX;
                }
                else if (e.clientX)
                {
                    x = e.clientX+(document.documentElement.scrollLeft || document.body.scrollLeft) - document.documentElement.clientLeft;
                }
                if (e.pageY)
                {
                    y = e.pageY;
                }
                else if (e.clientY)
                {
                    y = e.clientY+(document.documentElement.scrollTop || document.body.scrollTop) - document.documentElement.clientTop;
                }
                document.getElementsByClassName('cloud_1')[0].style.marginLeft =  `${x/120}px`
                document.getElementsByClassName('cloud_2')[0].style.marginLeft =  `${x/100}px`
                document.getElementsByClassName('cloud_3')[0].style.marginLeft =  `${x/170}px`
                document.getElementsByClassName('cloud_4')[0].style.marginLeft =  `${x/120}px`

                document.getElementsByClassName('cloud_1')[0].style.marginTop =  `${y/130}px`
                document.getElementsByClassName('cloud_2')[0].style.marginTop =  `${y/110}px`
                document.getElementsByClassName('cloud_3')[0].style.marginTop =  `${y/120}px`
                document.getElementsByClassName('cloud_4')[0].style.marginTop =  `${y/120}px`

                document.getElementsByClassName('ufo_1')[0].style.marginLeft =  `${x/60}px`
                document.getElementsByClassName('ufo_2')[0].style.marginLeft =  `${x/25}px`
                document.getElementsByClassName('ufo_3')[0].style.marginLeft =  `${x/80}px`

                document.getElementsByClassName('ufo_1')[0].style.marginTop =  `${y/60}px`
                document.getElementsByClassName('ufo_2')[0].style.marginTop =  `${y/60}px`
                document.getElementsByClassName('ufo_3')[0].style.marginTop =  `${y/60}px`
            })
        }
     })
    return(
        <div>
            {
                clouds.map(({img, className}) => <img key={className} alt="cloud" src={img} className={`cloudDefault ${className}`} />)
            }
            {
                ufos.map(({img, className}) => <picture key={className} alt="ufo" className={`ufoDefault ${className}`}>
                                                    <img src={img}/>
                                                </picture>
                )
            }
        </div>
    )
})