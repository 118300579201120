import footerBack from "../../img/footerBack.png"
import { FooterContacts } from '../FooterContacts'
import { FooterBottom } from '../FooterBottom'
import { memo } from "react"

export const Footer = memo(({}) => {
    return(
        <footer>
            <FooterContacts />
            <FooterBottom />
            <img src={footerBack} className="footerBack" />
        </footer>
    )
})









