import { memo } from "react"
import { Link } from "react-router-dom"
import { ProjectTechnologies } from '../ProjectTechnologies'

export const ProjectBlock = memo(({id, headline='', icon='', introduce='', newProject=false, technologies='', mainImg=''}) => {
    return(
        <Link to={`./${id}`} className='projectBlock' target="_blank">
            <div className={`projectBlockImg ${newProject&&'newProjectStatus'}`}>
                <img src={mainImg} />               
            </div>
            <h2 dangerouslySetInnerHTML={{__html: `${headline} ${icon}`}}>
            </h2>
            <ProjectTechnologies technologies={technologies}/>
            <p className='projectIntroduceShort'>
                {introduce}
            </p>
            <button>
                More
            </button>
        </Link>
    )
})
