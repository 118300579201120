import { memo } from "react"
import { Link } from "react-router-dom"
import { socialNetworksFooter } from '../../data'

export const FooterSocialNetwork = memo(({}) => {
    return(
        <div className="footerSocialNetwork">
            {
                socialNetworksFooter.map(({icon, link}, index) => <Link dangerouslySetInnerHTML={{ __html: icon }} to={link} key={index} target="_blank"/>)
            }
        </div>
    )
})