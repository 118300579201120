import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

export const ProjectContentBlockLeft = memo(({imgContent, category, icon, headline, readingTime, textContent, ButtonLink}) => {
    return(
        <section className="projectContentBlock">
            <div>
                <div className="projectCategory">
                    {category}
                </div>
                <div className="projectContentTextBlock">
                    <div className="projectContentIcon">
                        <FontAwesomeIcon icon={icon} />  
                    </div>
                    <div className="projectContentText">
                        <h2>
                            {headline}
                        </h2>
                        <div className="projectReadingTime">
                            <div>
                                Reading time
                            </div>
                            <div>
                                {readingTime} min
                            </div>
                        </div>
                        <p>
                            {textContent}
                        </p>
                        {ButtonLink}
                    </div>
                </div>
            </div>
            <div className="projectImgContent">
                {imgContent}
            </div>
        </section>
    )
})