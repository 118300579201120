import { memo } from "react"
import { Link } from "react-router-dom"
import { FooterSocialNetwork } from '../FooterSocialNetwork'

export const FooterContacts = memo(({}) => {
    return(
        <div className="footerContacts">
            <FooterSocialNetwork />
            <Link to="mailto:maksym.frontend@gmail.com">
                maksym.frontend@gmail.com
            </Link>
        </div>
    )
})