import React, { memo, useEffect, useRef, Suspense, useState } from "react";
import { NeonHeadline } from '../NeonHeadline'
import { socialNetworksLinks } from '../../data'

const SampleProjects = React.lazy(() => import("../SampleProjects/index.js"));

export const Experience = memo(({}) => {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef(null)
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting)
      })
      observer.observe(ref.current)
      return () => {
        observer.disconnect()
      }
    }, [])
    return(
        <section className="skillsBlock" ref={ref}>
            <NeonHeadline text='Background'/>
                {isIntersecting ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <SampleProjects />
                </Suspense>
            ) : null}
            <ExperienceText />
        </section>
    )
})

const ExperienceContent = [
    {
        number:'01',
        text:`I have gained some freelancing experience (FL, etc.) and have worked with the following companies: AvtovykupClub, TopExpress, Ospost, etc. My main position was a front-end developer, and I also have experience working in a team.`
    },
    {
        number:'02',
        text:`Of course, I don't stand in one place, because I try to improve my work area skills every single day. And I try to create interesting things or some projects and share it on <a href="${socialNetworksLinks['github']}">GitHub</a>.`
    }
]

const ExperienceText = memo(({}) => {
    return(
        <div className="experienceText">
            {
                ExperienceContent.map(
                    ({number, text}, index) => 
                        <div key={index}>
                            <div>{number}</div>
                            <div dangerouslySetInnerHTML={{__html:text}} />
                        </div>
                )
            }
        </div>
    )
})