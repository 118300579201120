import { memo } from "react"

export const ProjectTechnologies = memo(({technologies}) => {
    const technologiesArray = technologies.split(', ')
    return(
        <div className="projectTechnologies">
            {
                technologiesArray.map((name, index) => <div key={index} className={`${name.replace('/','')}-technology`}>{name}</div>)
            }
        </div>
    )
})