import { store } from '../../reducers/createStore'


export const actionPlayerIsStopped = () => ({type:'IS_STOPPED'})
export const actionPlayerVideoCurrentTime = (currentTime) => ({type:'CURRENT_TIME_VIDEO', currentTime})
export const actionPlayerVideoDuration = (duration) => ({type:'DURATION_VIDEO', duration})


export const videoActions = (urlVar) => 
    async (dispatch, getState) => { 
        const video = document.getElementById('videoPlayer')
        video.addEventListener('loadedmetadata', () => {
            dispatch(actionPlayerVideoDuration(video.duration))
          })
        video.ontimeupdate = () => {
            dispatch(actionPlayerVideoCurrentTime(video.currentTime))
        }
        !getState().videoPlayer.isStopped ? video.play() : video.pause()
}


export const videoStopWorker = () =>  
    async (dispatch, getState) => { 
        const video = document.getElementById('videoPlayer')
        await dispatch(actionPlayerIsStopped())
        !getState().videoPlayer.isStopped ? video.play() : video.pause()
  
}