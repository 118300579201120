import './App.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Root } from './components/Root'
import { history } from './helpers'
import { store } from './reducers/createStore'

const REACT_VERSION = React.version;
console.log(REACT_VERSION) // 18.2.0
console.log(history)

function App() {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<Root/>
			</Provider>
		</BrowserRouter>
	);
}

export default App;
