import { memo, useEffect } from 'react'
import introduceBlockBackground from '../../img/introduceBlockBackground.mp4'

export const IntroduceBlockBackground = memo(({}) => {
    useEffect(()=>{
		if (!/Mobi/.test(navigator.userAgent)){
			document.getElementsByClassName('introduceBlock')[0].addEventListener('mousemove', e => {
				let x, y
				if (e.pageX)
				{
					x = e.pageX;
				}
				else if (e.clientX)
				{
					x = e.clientX+(document.documentElement.scrollLeft || document.body.scrollLeft) - document.documentElement.clientLeft;
				}
				if (e.pageY)
				{
					y = e.pageY;
				}
				else if (e.clientY)
				{
					y = e.clientY+(document.documentElement.scrollTop || document.body.scrollTop) - document.documentElement.clientTop;
				}
				document.getElementById('myVideo').style.marginLeft =  `${x/120}px`
				document.getElementById('myVideo').style.marginTop =  `${y/30}px`
			})
		}
     })
    return(
        <video autoPlay muted loop id="myVideo">
            <source src={introduceBlockBackground} type="video/mp4" />
        </video>
    )
})