import { memo, useEffect, useState } from "react"
import { Footer } from '../../components/Footer'
import { HeadlineMain } from '../../components/HeadlineMain'
import { ContactForm } from '../../components/ContactForm'
import { Alert } from '../../components/Alert'
import MyPhotoContact from '../../img/myPhotoContact.svg'
import { actionFeedbackForm } from '../../actions/contact'
import { connect } from "react-redux"

const Contact = memo(({onSubmit, data}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    useEffect(() => {
        if (isSuccess) {
          const timer = setTimeout(() => {
            setIsSuccess(false);
          }, 5000);
          return () => clearTimeout(timer)
        }
      }, [isSuccess])
      useEffect(() => {
        if (data){
            setIsSuccess(true);
        }
      }, [data]);
    return(
        <>
            <div className="mainBlock">
                <HeadlineMain text='Contact' />
                <div className="contactBlock">
                    <ContactForm onSubmit={onSubmit}/>
                    <div className="contactBlockAvatar">
                        <img src={MyPhotoContact} /> 
                    </div>
                </div>
            </div>
            <Footer />
            {isSuccess && <Alert state={data?.success} />}            
        </>
    )
})

export const CContact = connect(store => ({data: store?.promise?.feedbackForm?.payload}),{onSubmit: (x) => actionFeedbackForm(x)})(Contact)