import { memo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { MenuLink } from '../MenuLink'
import { SocialNetworks } from '../SocialNetworks'
import { menuLinks } from '../../data'

export const Menu = memo(({}) =>{
    const [status, setStatus] = useState(false)
    const changeStatus = () => setStatus(!status)
    return(
        <>
            <div onClick={changeStatus} className="icon">
                {
                    status?
                        <FontAwesomeIcon icon={faSquareXmark} />            
                    :
                        <FontAwesomeIcon icon={faBars} />
                }
            </div>
            {
                status &&
                    <div className="menu">
                        <h1>Menu</h1>
                        <nav>
                            {
                                menuLinks.map(({name, icon, link}) => <MenuLink key={name} name={name} icon={icon} link={link} onClick={changeStatus}/>)
                            }
                        </nav>
                        <SocialNetworks />
                    </div>
            }
        </>
    )
})