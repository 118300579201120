import { memo, useState } from "react";

import { NetworksLink } from '../NetworksLink'
import { socialNetworks } from '../../data'


export const SocialNetworks = memo(({className}) => {

    return(
        <div className={`socialNetworksBlock ${className}`}>
            {
                socialNetworks.map(({icon, link}) => <NetworksLink key={link} icon={icon} link={link} />)
            }
        </div>
    )
})