import React, { memo, useEffect, useRef, Suspense, useState } from "react"

const AllImgProjec = React.lazy(() => import("../AllImgProjec/index.js"))

export const StructureProjec = memo(({category, imgs=[]}) => {
    const [isIntersecting, setIntersecting] = useState(false)
    const ref = useRef(null)
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting)
      })
      observer.observe(ref.current)
      return () => {
        observer.disconnect()
      }
    }, [])
    imgs.sort((a,b) => parseInt(b.id) - parseInt(a.id))
    return(
        <section ref={ref}>
            <div className="projectCategory">
                {category}
            </div>
            {isIntersecting ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AllImgProjec imgs={imgs}/>
                    </Suspense>
                ) : null}
        </section>
    )
})


