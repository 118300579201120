import { memo } from "react"

export const ColorBlock = memo(({hexCode, name}) => {
    return(
        <div className="colorBlock">
            <div style={{background: `${hexCode}`}} />
            <div>
                {hexCode}
            </div>
            <div>
                {name}
            </div>
        </div>
    )
})