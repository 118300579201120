import { memo } from "react";
import { Link } from "react-router-dom";


export const NotFound404 = memo(({}) => {
    return(
        <div className="notFound404">
           <Link to='/'>
                Go back to the main page
           </Link>
        </div>
    )
})
