import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'
import { Link } from 'react-router-dom'

export const ProjectButtonLink = memo(({icon, text, link}) => {
    return(
        <>
            { link && 
                <Link to={link} style={{width: '100%'}} target="_blank">
                <div className="projectButtonLink">
                    <div>
                        <FontAwesomeIcon icon={icon} />
                        {text} 
                        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 22.85C18.7 22.55 18.55 22.1917 18.55 21.775C18.55 21.3583 18.6833 21.0167 18.95 20.75L26.3 13.4H1.5C1.06667 13.4 0.708333 13.2583 0.425 12.975C0.141667 12.6917 0 12.3333 0 11.9C0 11.4667 0.141667 11.1083 0.425 10.825C0.708333 10.5417 1.06667 10.4 1.5 10.4H26.3L18.9 3C18.6 2.7 18.4583 2.35 18.475 1.95C18.4917 1.55 18.65 1.2 18.95 0.9C19.25 0.633333 19.6083 0.5 20.025 0.5C20.4417 0.5 20.7833 0.633333 21.05 0.9L31 10.85C31.1667 11.0167 31.2833 11.1833 31.35 11.35C31.4167 11.5167 31.45 11.7 31.45 11.9C31.45 12.1 31.4167 12.2833 31.35 12.45C31.2833 12.6167 31.1667 12.7833 31 12.95L21.1 22.85C20.8 23.15 20.45 23.3 20.05 23.3C19.65 23.3 19.3 23.15 19 22.85Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </Link>
            }
        </> 
    )
})