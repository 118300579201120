import { memo } from "react"

export const CodeBlock = memo(({code = ''}) => {
    code = code.replace(/</g, `&lt;`).replace(/>/g, `&gt;`)
                .replace(/\//g, `SLASH`)
                .replace(/=/g, `EQUALS`)
                .replace(/class/g, `CLASS`)
                .replace(/srcset/g, `SRCSET`)
                .replace(/type/g, `TYPE`)
                .replace(/alt/g, `ALT`)
                .replace(/style/g, `STYLE`)
                .replace(/src/g, `SRC`)
                .replace(/width/g, `WIDTH`)
                .replace(/height/g, `HEIGHT`)
                .replace(/"(.*?)"/g, (match) => `VALUE ${match} VALUE`);
    code = code.replace(/&lt;/g, `<span style="color:#fff">&lt;</span>`)
                .replace(/SLASH/g, `<span style="color:#fff">/</span>`)
                .replace(/SRCSET/g, `<span style="color:#d2a8ff">srcset</span>`)
                .replace(/SRC/g, `<span style="color:#d2a8ff">src</span>`)
                .replace(/ALT/g, `<span style="color:#d2a8ff">alt</span>`)
                .replace(/TYPE/g, `<span style="color:#d2a8ff">type</span>`)
                .replace(/STYLE/g, `<span style="color:#d2a8ff">style</span>`)
                .replace(/CLASS/g, `<span style="color:#d2a8ff">class</span>`)
                .replace(/HEIGHT/g, `<span style="color:#d2a8ff">height</span>`)
                .replace(/WIDTH/g, `<span style="color:#d2a8ff">width</span>`)
                .replace(/EQUALS/g, `<span style="color:#fff">=</span>`)
                .replace(/VALUE (.*?) VALUE/g, (match) => `<span style="color: #a5d6ff">${match}</span>`)
                .replace(/VALUE /g, ``)
                .replace(/ VALUE/g, ``)
                .replace(/&gt;/g, `<span style="color:#fff">&gt;</span> END`);
   
    return(
        <div className="GitHub_body_code">
            <div>
                {((code.replace(/\n/g, '')).split(' END'))?.map(
                    (string, index) =>
                        <pre dangerouslySetInnerHTML={{__html:string}} key={index}/>
                )}
            </div>            
        </div> 
    )
})