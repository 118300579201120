import { memo } from "react";
import { Logo } from "../Logo";
import { Menu } from "../Menu";

export const Header = memo(({}) => {
    return(
        <header>
            <Logo />
            <Menu />
        </header>  
    )
})