import { Route, Routes } from 'react-router-dom'
import { Header } from '../Header'
import { Main } from '../../pages/Main'
import { About } from '../../pages/About'
import { CProjects } from '../../pages/Projects'
import { CContact } from '../../pages/Contact'
import { CProject } from '../../pages/Projects/Project'
import { NotFound404 } from '../../pages/NotFound404'
import { memo } from 'react'

export const Root = memo(() => {
    return (
        <>
            <Header />    
            <Routes>
                <Route path="/projects/:id" element={<CProject />} />
                <Route path="/projects" element={<CProjects />} />
                <Route path="/contact" element={<CContact />} />
                <Route path="/about" element={<About />} />
                <Route path="/" element={<Main />} />
                <Route path="*" element={<NotFound404 />} />
            </Routes>
        </>
    )
})