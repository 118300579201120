import { memo, useEffect } from "react"

function planetaryRotation(planetNumber){
    const moveAnimation = document.createElement('style')
    const nameAnimation = `solarSystem${planetNumber}`
    const random = Math.random()
    const result = random >= 0.5 ? 1 : -1
    moveAnimation.innerHTML = `
    @keyframes ${nameAnimation} {
        from {
        transform: translate(-50%, -50%) rotate(0deg) translateX(${result * (92+(45*(planetNumber-2)))}px) rotate(0deg);
        }
        to {
        transform: translate(-50%, -50%) rotate(360deg) translateX(${result * (92+(45*(planetNumber-2)))}px) rotate(-360deg);
        }
    }
    `
    document.head.appendChild(moveAnimation)
    return {
        nameAnimation:nameAnimation,
        translateX: (result * (92+(45*(planetNumber-2))))
    }
}

function createNestedDivs(parent, depth, planetsArray) {
    if (depth == 1) {
        const planet = document.createElement('div')
        planet.className = `${planetsArray[depth - 1]?.replace('/','')}-technology planet planetCenter`
        planet.innerText = planetsArray[depth - 1]
        parent.appendChild(planet)
        return;
    }
    const axis = document.createElement('div')
    axis.className = 'axis'
    axis.style = `
        animation: solarSystem ${(depth/2 + 5)}s infinite linear;
        animation-delay: ${(5 + depth)}s;
    `
    const newPlanetaryRotation = planetaryRotation(depth)
    const planet = document.createElement('div')
    planet.className = `${planetsArray[depth - 1]?.replace('/','')}-technology planet`
    planet.innerText = planetsArray[depth - 1]
    planet.style = `width: ${planetsArray[depth - 1].length*14}px; height: ${planetsArray[depth - 1].length*14}px; transform: translate(-50%, -50%) rotate(0deg) translateX(${newPlanetaryRotation.translateX}px) rotate(0deg);animation: ${newPlanetaryRotation.nameAnimation} ${(10*depth/2)}s linear infinite; animation-delay: ${(depth/2)}s;`
    axis.appendChild(planet)
    parent.appendChild(axis)
    if (planetsArray.length == depth) {
        const newPlanetaryRotation2 = planetaryRotation(depth-1)
        const planet = document.createElement('div')
        planet.className = `${planetsArray[depth - 2]?.replace('/','')}-technology planet`
        planet.innerText = planetsArray[depth - 2]
        planet.style = `width: ${planetsArray[depth - 2].length*14}px; height: ${planetsArray[depth - 2].length*14}px; transform: translate(-50%, -50%) rotate(0deg) translateX(${newPlanetaryRotation2.translateX}px) rotate(0deg);animation: ${newPlanetaryRotation2.nameAnimation} ${(10*(depth-1)/2)}s linear infinite; animation-delay: ${((depth-1)/2)}s;` 
        axis.appendChild(planet)
        createNestedDivs(axis, depth - 1, planetsArray)
        return;
    }
    createNestedDivs(axis, depth - 1, planetsArray)
}
  
export const SolarSystem = memo(({technologies=''}) => {
    useEffect(() => {
        let technologiesArray = technologies.split(', ')
        technologiesArray = technologiesArray.slice(0, 6);
        const container = document.getElementById('solarSystem')
        container.innerHTML = '' 
        createNestedDivs(container, technologiesArray.length, technologiesArray);
    },[technologies])

    return(
        <div id="solarSystem" />
    )
}) 