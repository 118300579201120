import { memo } from "react"
import { NeonHeadline } from '../NeonHeadline'
import { CopycatGitHub } from '../CopycatGitHub'
import { SkillsText } from '../SkillsText'

export const Skills = memo(({}) => {
    return(
        <section className="skillsBlock" id="SkillsBlock">
            <NeonHeadline text='Skills' className='' />
            <CopycatGitHub />
            <SkillsText />
        </section>
    )
})