import { memo } from "react"
import { Link } from "react-router-dom"

export const Headline = memo(({})=>{
    return(
        <div className="headlineMain">
            <div className="headlineMainPhrase">
                We are not alone
            </div>
            <h1>
                I believe <mark>that</mark>...
            </h1>
            <div className="headlineMainDescription">
                <p>
                    I’m a Front-End developer from Ukraine. Most of the time I do Front-End development, but I also have Back-End & UX/UI Designer skills.
                </p>
                <Link to='/about'>MORE</Link>
            </div>
        </div>
    )
}) 