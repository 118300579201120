import { memo } from "react"

export const ColorSectionBlock = memo(({children, style})=>{
    return(
        <section className="colorSection" style={style}>
            <svg className="blockGraphic_1" preserveAspectRatio="none"  viewBox="0 0 1728 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1728 63.4867L1655.89 46.3155C1583.79 29.1444 1439.58 -5.19782 1295.37 0.665486C1152.42 6.10999 1008.21 52.1788 864 57.6233C719.789 63.4867 575.578 29.1444 431.367 23.2811C288.422 17.8366 144.211 40.4522 72.1053 52.1788L-7.65324e-05 63.4867V115H72.1053C144.211 115 288.422 115 431.367 115C575.578 115 719.789 115 864 115C1008.21 115 1152.42 115 1295.37 115C1439.58 115 1583.79 115 1655.89 115H1728V63.4867Z" fill="#B1D7D2"/>
            </svg>
                {children}
            <svg className="blockGraphic_2" preserveAspectRatio="none" viewBox="0 0 1366 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 56.6667H57C114 56.6667 228 56.6667 342 59.8916C455 62.8862 569 69.336 683 64.4986C797 59.8916 911 43.9973 1025 45.6098C1138 47.2222 1252 66.1111 1309 75.5556L1366 85V0H1309C1252 0 1138 0 1025 0C911 0 797 0 683 0C569 0 455 0 342 0C228 0 114 0 57 0H0V56.6667Z" fill="#B1D7D2"/>
            </svg>
        </section>
    )
})