import React, { memo, useEffect, useRef, Suspense, useState } from "react"
import { ColorSectionBlock } from '../ColorSectionBlock'
import { ColorSectionText  } from '../ColorSectionText'
import WhoAmI_img1 from '../../img/WhoAmI1.jpg'
import WhoAmI_img2 from '../../img/WhoAmI2.jpg'
import WhoAmI_img3 from '../../img/WhoAmI3.jpg'
import { socialNetworksLinks } from '../../data'

const WhoAmI_block = {
    headline:'Who am I ?',
    texts: [
        {
            text:`I’m a simple guy from the beautiful country of Ukraine, who wants to make the world a little better.`
        },
        {
            text:`Since 2020, I've taken pleasure in creating a website. 4 months after the start of my studies, I successfully defended my graduation project (it was the first website). And after 1 month I obtained the first commercial order.`
        },
        {
            text:`As well, I really like to travel, cook and just enjoy strolling... more on my <a href='${socialNetworksLinks['instagram']}' class='instagramLink' ><span></span>Instagram</a>.`
        }
    ],
    imgs:[
        {
            img: WhoAmI_img1,
            className: 'containerMiniGallery_main',
            info:{
                plase: `Princes' Islands`,
                link: 'https://www.instagram.com/p/CUalXXQM20v/',
                date: 'September 29, 2021',
                comment: '🌿'
            }
        },
        {
            img: WhoAmI_img2,
            className: 'containerMiniGallery_next',
            info:{
                plase: 'Istanbul',
                link: 'https://www.instagram.com/p/CUVTXsJsBkJ/',
                date: 'September 27, 2021',
                comment: 'Turkey 😁'
            }
        },
        {
            img: WhoAmI_img3,
            className: 'containerMiniGallery_last',
            info:{
                plase: 'Greece',
                link: 'https://www.instagram.com/p/CSWXi4vKUG2/',
                date: 'August 9, 2021',
                comment: '🏝🏖🏞'
            }
        },
    ]
}

const MiniGallery = React.lazy(() => import("../MiniGallery/index.js"))

export const WhoAmI = memo(({})=>{
    const [isIntersecting, setIntersecting] = useState(false)
    const ref = useRef(null)
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting)
      })
      observer.observe(ref.current)
      return () => {
        observer.disconnect()
      }
    }, [])
    return(
        <ColorSectionBlock style={{marginTop: '0'}}>
            <ColorSectionText headline={WhoAmI_block.headline} texts={WhoAmI_block.texts}/>
            <div className="containerMiniGallery" ref={ref} id="MiniGalleryInstagram">
                {isIntersecting ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <MiniGallery imgs={WhoAmI_block.imgs}/>
                    </Suspense>
                ) : null}
            </div>
        </ColorSectionBlock>
    )
})