export function videoReducer(state = {
    isStopped:false, 
    isPlaying:true, 
    currentTime:0, 
    soundVolume:1,
    duration:0

}, 
{
type,
currentTime,
duration

}){

    if(type === 'IS_STOPPED'){
  
		return {
			...state,
			isStopped:!state.isStopped,
			isPlaying:!state.isPlaying
		}
  
    }

    
    if(type === "CURRENT_TIME_VIDEO"){ 
  
		return {
			...state,
			currentTime: currentTime
		}
  
    }

    if(type === "DURATION_VIDEO"){ 
  
		return {
			...state,
			duration: duration
		}
  
    }

    return state || {}
  
}