import { memo } from "react"
import { IntroduceBlockBackground } from '../IntroduceBlockBackground'
import { IntroduceHeader } from '../IntroduceHeader'
import { PhoneBlock } from '../PhoneBlock'

export const IntroduceBlock = memo(({})=>{
    return(
        <section className="introduceBlock">
            <IntroduceBlockBackground />
            <IntroduceHeader />
            <PhoneBlock />
        </section>
    )
})