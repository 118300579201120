import { memo } from "react"
import { SocialNetworks } from '../../components/SocialNetworks'
import { Headline } from '../../components/Headline'
import { Alien } from '../../components/Alien'
import { AdVideo } from '../../components/AdVideo'

export const Main = memo(() => {
    return(
        <>
            <div className="backgroundMain" id='MainPage'>
                <Alien />
                <Headline />
                <SocialNetworks className='socialNetworksMain'/>
            </div>
            <AdVideo />       
        </>
    )
}
)






