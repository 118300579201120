import { CIntroduceSpeech } from '../IntroduceSpeech'
import { memo, useState } from "react"
import trailerVideo from '../../img/trailerVideo.gif'

export const AdVideo = memo(() => {
    const [state, setState] = useState(false)
    const stateVideo = () => setState(!state)
    return(
        <>
            {state? <CIntroduceSpeech close={stateVideo} /> :
                <div className="adVideoBlock" onClick={stateVideo}>
                    <div className="adVideo">
                        <img src={trailerVideo} />
                    </div>
                    <div className="adVideoBlockIcon">
                        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_34_24)">
                                <circle cx="9.9325" cy="14.5581" r="6.67798" fill="#183153"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M23.4502 0.24848L14.6073 3.64819L14.712 2.3147C14.7575 1.73215 14.1294 1.3362 13.6197 1.62748L5.59145 6.27875C2.73788 7.93082 0.98114 10.9755 0.98114 14.2706C0.98114 19.3724 5.11814 23.5094 10.22 23.5094C13.515 23.5094 16.5643 21.7527 18.2163 18.8991L22.8631 10.8709C23.1589 10.3657 22.7629 9.73308 22.1758 9.77859L20.8424 9.88326L24.2421 1.04038C24.2694 0.972113 24.283 0.894743 24.283 0.821925C24.283 0.480589 24.01 0.20752 23.6686 0.20752C23.5913 0.20752 23.5184 0.221173 23.4502 0.24848ZM9.71935 20.5967C6.50169 20.5967 3.89388 17.9889 3.89388 14.7712C3.89388 11.5535 6.50169 8.94573 9.71935 8.94573C12.937 8.94573 15.5448 11.5535 15.5448 14.7712C15.5448 17.9889 12.937 20.5967 9.71935 20.5967Z" fill="#F2554C"/>
                                <path d="M10.4475 17.6839C10.848 17.6839 11.1757 17.3563 11.1757 16.9558C11.1757 16.5553 10.848 16.2276 10.4475 16.2276C10.047 16.2276 9.71935 16.5553 9.71935 16.9558C9.71935 17.3563 10.047 17.6839 10.4475 17.6839Z" fill="white"/>
                                <path d="M9.71935 13.3148C9.71935 12.5093 9.06853 11.8585 8.26298 11.8585C7.45743 11.8585 6.80661 12.5093 6.80661 13.3148C6.80661 14.1204 7.45743 14.7712 8.26298 14.7712C9.06853 14.7712 9.71935 14.1204 9.71935 13.3148Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_34_24">
                                    <rect width="23.3019" height="23.3019" fill="white" transform="translate(0.98114 0.20752)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            }
        </>
    )
})