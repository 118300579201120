import { originalFetch } from "./originalFetch"
import axios from 'axios';



function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}



const csrfToken = () => {
  return axios.get('https://frontmax.dev/project/csrf_token/')
    .then(response => response.data.csrf_token)
    .catch(error => {
      console.log('Error:', error);
    });
};

export const getGQL = url => async (query, variables, anon = false) => {
  /*const token = await csrfToken();*/
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': "vWai1aAXUq6Gkexa1X5xJayIv6xCRHx6LR9VaLdZrQr6C5uzqKuKnEP6dVtgKL4s",
    ...((localStorage.authToken && !anon) ? {"Authorization": "Bearer " + localStorage.authToken} : {})
  };
  const body = JSON.stringify({query, variables});
  return fetch(url, { method: 'POST', credentials: 'include', headers, body })
    .then(response => response.json())
    .then(data => {
      if (data.data) {
        return Object.values(data.data)[0];
      } else {
        throw new Error(JSON.stringify(data.errors));
      }
    })
    .catch(error => {
      console.log('Error:', error);
    });
};