import { memo } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Controller } from "react-hook-form"
import PhoneInput from 'react-phone-number-input'


export const Input = memo(({type, className, control, style={}, nameInput='', placeholder='', name='', dataInput, errors={}}) => {
    return(
        <>
            {
                (type=='text' || type=='email')&&
                <div className={`defaultInput ${className}`} style={style}>
                    <label>{nameInput}</label>
                    <input type='text' {...dataInput}/>

                    {
                        Object.keys(errors[name] || {}).length != 0 && <FontAwesomeIcon icon={faXmark} className={'red'}/> 
                    }
                </div>
            }
            {
                type=='textarea'&&
                <div className={`defaultInput ${className}`} style={style}>
                    <label>{nameInput}</label>
                    <textarea type='textarea' {...dataInput}/>
                    {
                        Object.keys(errors[name] || {}).length != 0 && <FontAwesomeIcon icon={faXmark} className={'red'}/> 
                    }
                </div>
            }
            {
                type=='url'&&
                <div className={`defaultInput urlInput ${className}`} style={style}>
                    <label>{nameInput}</label>
                    <div>
                        https://&nbsp;
                    </div>
                    <input type='url' {...dataInput}/>
                    {
                        Object.keys(errors[name] || {}).length != 0 && <FontAwesomeIcon icon={faXmark} className={'red'}/> 
                    }
                </div>
            }
            {
                type=='phoneNumber'&&
                    <div className={`defaultInput inputPhoneNumber ${className}`} style={style}>
                        <label>{nameInput}</label>
                        <Controller
                                control={control}
                                name={name}
                                render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    international
                                    
                                    placeholder=""
                                    value={value}
                                    onChange={onChange}
                                />
                                )}
                            />   
                        {
                            Object.keys(errors[name] || {}).length != 0 && <FontAwesomeIcon icon={faXmark} className={'red'}/> 
                        }
                    </div>
            }
            {
                Object.keys(errors[name] || {}).length != 0 &&
                <div className="requirementsInputBlock">
                    <div>Requirements</div>
                    <ul>
                        <li>
                           {errors[name].message}
                        </li>
                    </ul>
                </div>
            }
        </>
    )
})