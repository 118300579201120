import { memo } from "react"

export const FooterQuote = memo(({}) => {
    return(
        <div className="footerQuote">
            <blockquote cite="https://abkhazworld.com/aw/art-and-living/1982-the-little-prince-premiered-at-the-drama-theatre">
                <p>
                    “All <mark rightword="seniors">adults</mark> were <mark rightword="juniors">children</mark> at first, only few of them remember this.”
                </p>
            </blockquote>
            <figcaption>
                Little Prince
            </figcaption>
        </div>
    )
})