import { memo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink} from "react-router-dom";

export const NetworksLink = memo(({icon, link}) => {

    return(
        <Link to={link} target="_blank">
            <FontAwesomeIcon icon={icon} />
        </Link>
    )
})