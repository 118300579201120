import { memo } from "react"
import { Logo } from "../Logo"
import { FooterQuote } from "../FooterQuote"
import { Copyright } from "../Copyright"

export const FooterBottom = memo(({}) => {
    return(
        <div className="footerBottom">
            <Logo />
            <FooterQuote />
            <Copyright />
        </div>
    )
})