import { memo } from "react"
import { useForm } from "react-hook-form"
import { DefaultButtonFull } from '../DefaultButtonFull'
import { Input } from '../Input'

export const ContactForm = memo(({className='', onSubmit}) => {
    const { register, handleSubmit, watch, control, formState: { errors }, reset } = useForm({mode:"onChange"});
    const handleFormSubmitAndReset = (data) => {
        onSubmit(data)
        reset()
    }
    return(
        <form onSubmit={handleSubmit(handleFormSubmitAndReset)} className={`contactForm ${className}`}>
            <h2>Send me feedback</h2>
            <Input nameInput='Name' type='text' name="name" dataInput={{...register("name", { required: {value:true, message:'This is a required field'}, minLength:{value:3, message:'Length must be at least 3 characters' } })}} errors={errors}/>
            <Input type='phoneNumber' control={control} nameInput='Phone number' name="phoneNumber" dataInput={{...register("phoneNumber", { required: {value:true, message:'This is a required field. Please check that your phone number is entered correctly'}, minLength:{value:10, message:'Length must be at least 10 characters' }})}} errors={errors}/>
            <Input nameInput='Email' type='email' name="email"  dataInput={{...register("email", { required: {value:true, message:'This is a required field'}, pattern: {value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message:"Please check that your email is entered correctly"}, minLength:{value:12, message:'Length must be at least 12 characters' } })}} errors={errors}/>
            <Input type='textarea' nameInput='Message' name="message" dataInput={{...register("message", { required: {value:true, message:'This is a required field'}, minLength:{value:3, message:'Length must be at least 3 characters' }})}} style={{height: '153px'}} errors={errors}/>
            <DefaultButtonFull text='Send message' type="submit"/>
        </form>
    )
})