import { memo } from "react"

export const IntroduceProjectBackground = memo(({img, icon}) => {
    return(
        <>
            <div className="introduceProjectBackground">
                <div className="blackout" />
                <img src={img}/>
            </div>       
            <div className="introduceBackgroundIcon" dangerouslySetInnerHTML={{__html: `${icon}, ${icon}, ${icon}, ${icon}, ${icon}`}} />
        </>
    )
})