import { memo } from "react"

export const ColorSectionText = memo(({headline = '', texts = []})=>{
    return(
        <div className="colorSectionTextBlock">
            <h2>
                {headline}
            </h2>
            <div>
                {texts.map(({text}) => <p key={text} dangerouslySetInnerHTML={{__html: text }} />)}
            </div>
        </div>
    )
})