import { memo } from "react"
import { IntroduceProjectBackground } from '../IntroduceProjectBackground'

export const IntroduceProjectBlock = memo(({headline='', icon='', introduce='', mainImg=''}) => {
    return(
        <section className="introduceProjectBlock">
            <article>
                <h1>{headline}</h1>
                <p>{introduce}</p>
            </article>
            <IntroduceProjectBackground img={mainImg} icon={icon}/>
        </section>
    )
})