import { actionPromise } from "../store"

export const actionFeedbackForm = ({name, email, message, phoneNumber}) => {


    return (dispatch) => {
        let sendDataPromise =  new Promise(
            (resolve, reject) => {

                const sendData = fetch(`https://frontmax.dev/project/send_telegram_message/?name=${name}&phoneNumber=${phoneNumber}&email=${email}&message=${message}`);

                if(sendData){
                    sendData.then(response => response.json())
                    .then(data => resolve(data));
                }
                else{
                    reject('ERROR !')
                }


            }
        )

        dispatch(actionPromise('feedbackForm', sendDataPromise))
    }

}