import { memo } from "react";
import { Link } from "react-router-dom";

const scrollOneScreen = () => {
    const screenHeight = window.innerHeight;
    window.scrollBy(0, screenHeight);
  }

export const IntroduceHeader = memo(({}) => {
    return(
        <div className="introduceHeaderBclock">
            <article className="introduceHeader">
                <h1>
                    Let's get to know<br/>each other<br/>better
                </h1>
                <p>
                    I really like space, but I love front-end development even more than that. Well...
                </p>
            </article>
            <div className="introduceButton">
                <Link to='/contact' className="myButtonTriangleFull">
                    Contact
                </Link>
                <Link to='' onClick={scrollOneScreen} className="myButtonTriangleEmpty">
                    More
                </Link>
            </div>
        </div>
    )
})
