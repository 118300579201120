import React, { useState, useRef, memo, useEffect, Suspense } from "react";
import { Footer } from '../../components/Footer'
import { IntroduceBlock } from '../../components/IntroduceBlock'
import { Experience } from '../../components/Experience'
import { WhoAmI } from '../../components/WhoAmI'
import { Education } from '../../components/Education'
import { Skills } from '../../components/Skills'

export const About = memo(({}) => {
    return(
        <>
            <IntroduceBlock />
            <WhoAmI />
            <Skills />
            <Education />
            <Experience />
            <Footer />
        </>
    )
})




