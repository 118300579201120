import React, { memo, useEffect, useRef, Suspense, useState } from "react";
import { ColorSectionBlock } from '../ColorSectionBlock'
import { ColorSectionText  } from '../ColorSectionText'
import Education1 from '../../img/education1.jpg'
import Education2 from '../../img/education2.jpg'
import Education3 from '../../img/education3.jpg'

const Education_block = {
    headline:'Education',
    texts: [
        {
            text:`In 2020, I graduated with honours from KRTC (Kharkiv Radio-Technical College). As well this year, I entered the university (KhPI). And I'm going to graduate in 2023. My specialty is software engineer.`
        },
        {
            text:`In 2022 I completed courses (Front End React) from NIX/A-Level and successfully defended graduation project.`
        },
        {
            text:`I usually get inspired by various sources, such as YouTube channels (Online Tutorials, etc.), special websites (reactjs.org, etc.), and more.`
        }
    ],
    imgs:[
        {
            img: Education1,
            className: 'containerMiniGallery_main',
            info:{
                plase: `KRTC`,
                link: 'https://www.facebook.com/maksym19.06.01',
                date: '29 June 2020 y.',
                comment: 'Education is not the filling of a pail, but the lighting of a fire'
            }
        },
        {
            img: Education2,
            className: 'containerMiniGallery_next',
            info:{
                plase: 'KRTC',
                link: 'https://www.facebook.com/maksym19.06.01',
                date: 'September 27, 2021',
                comment: 'The beautiful thing about learning is that no one can take it away from you'
            }
        },
        {
            img: Education3,
            className: 'containerMiniGallery_last',
            info:{
                plase: 'NIX/A-Level',
                link: 'https://www.facebook.com/maksym19.06.01',
                date: 'August 9, 2021',
                comment: 'Education is the passport to the future, for tomorrow belongs'
            }
        },
    ]

}

const MiniGalleryFacebook = React.lazy(() => import("../MiniGalleryFacebook/index.js"))

export const Education = memo(({})=>{
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, []);
    return(
        <ColorSectionBlock>
            <ColorSectionText headline={Education_block.headline} texts={Education_block.texts}/>
            <div className="containerMiniGallery" ref={ref} id="MiniGalleryFacebook">
                {isIntersecting ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <MiniGalleryFacebook imgs={Education_block.imgs}/>
                    </Suspense>
                ) : null}
            </div>
        </ColorSectionBlock>
    )
})