import { memo } from "react"
import ColorHeaderImg from '../../img/colorHeader.svg'
import Palette1 from '../../img/palette1.svg'
import Palette2 from '../../img/palette2.svg'
import Tassel from '../../img/tassel.svg'
import { ColorBlock } from '../ColorBlock'
import { Link } from 'react-router-dom';

export const ColorProjec = memo(({category, colors=[]}) => {
    return(
        <section className="colorProjecBlock">
            <div className="projectCategory">
                    {category}
            </div>
            <div className="colorProjecHeader">
                <h2>
                    Color palette
                </h2>
                <img src={ColorHeaderImg} />
            </div>
            <div className="colorProjecContent">
                <div className="colorsBlock">
                    {
                        colors.map(({id, colorId}) => <ColorBlock key={id} hexCode={colorId?.hexCode} name={colorId?.name} />)
                    }
                </div>
                <p>
                    *The palette was created automatically using the <Link target="_blank" to='https://color.adobe.com/'>Adobe Color</Link> service
                </p>
            </div>
            <img src={Palette1} className='palette1'/>
            <img src={Palette2} className='palette2'/>
            <img src={Tassel} className='tassel'/>
        </section>
    )
})
