import { memo, useState } from "react";
import { CIntroduceSpeech } from '../IntroduceSpeech'

export const PhoneBlock = memo(({}) => {
    let date = new Date();
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let minutesForm = ((`${ minutes }`).length === 1 ? '0':'') + minutes 
    const [state, setState] = useState(false);
    const stateVideo = ()=>setState(!state)
    return(
        <>
            <div className="phoneBlock">
                <div className="phoneFrame">
                    <div className="phoneScreen">
                        <div className="phoneWallpaper" />
                        <div className="phoneHeader">
                            <time>{hours}:{minutesForm}</time>
                            <div className="phoneCamera">
                                <div className="phoneCameraHole">
                                    <div className="phoneCameraFocus">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="phoneIcons">
                                <svg width="10" height="7" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.44444 0C9.75174 0 10 0.251367 10 0.5625V8.4375C10 8.74863 9.75174 9 9.44444 9C9.13715 9 8.88889 8.74863 8.88889 8.4375V0.5625C8.88889 0.251367 9.13715 0 9.44444 0ZM7.22222 1.6875C7.52951 1.6875 7.77778 1.93887 7.77778 2.25V8.4375C7.77778 8.74863 7.52951 9 7.22222 9C6.91493 9 6.66667 8.74863 6.66667 8.4375V2.25C6.66667 1.93887 6.91493 1.6875 7.22222 1.6875ZM5.55556 3.9375V8.4375C5.55556 8.74863 5.30729 9 5 9C4.69271 9 4.44444 8.74863 4.44444 8.4375V3.9375C4.44444 3.62637 4.69271 3.375 5 3.375C5.30729 3.375 5.55556 3.62637 5.55556 3.9375ZM2.77778 5.0625C3.08507 5.0625 3.33333 5.31387 3.33333 5.625V8.4375C3.33333 8.74863 3.08507 9 2.77778 9C2.47049 9 2.22222 8.74863 2.22222 8.4375V5.625C2.22222 5.31387 2.47049 5.0625 2.77778 5.0625ZM1.11111 7.3125V8.4375C1.11111 8.74863 0.862847 9 0.555556 9C0.248264 9 0 8.74863 0 8.4375V7.3125C0 7.00137 0.248264 6.75 0.555556 6.75C0.862847 6.75 1.11111 7.00137 1.11111 7.3125Z" fill="white"/>
                                </svg>
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.846928 2.67031C1.92524 1.63594 3.388 1 5.00078 1C6.61356 1 8.07632 1.63594 9.15463 2.67031C9.35467 2.86094 9.67035 2.85469 9.86101 2.65625C10.0517 2.45781 10.0454 2.14062 9.84694 1.95C8.59047 0.742188 6.88236 0 5.00078 0C3.1192 0 1.41109 0.742188 0.153056 1.94844C-0.0454164 2.14062 -0.0516675 2.45625 0.138991 2.65625C0.329649 2.85625 0.646893 2.8625 0.845365 2.67031H0.846928ZM5.00078 3.5C5.88844 3.5 6.69795 3.82969 7.31681 4.375C7.52466 4.55781 7.84034 4.5375 8.02319 4.33125C8.20603 4.125 8.18572 3.80781 7.97943 3.625C7.18554 2.925 6.14161 2.5 5.00078 2.5C3.85996 2.5 2.81602 2.925 2.0237 3.625C1.81585 3.80781 1.79709 4.12344 1.97994 4.33125C2.16278 4.53906 2.47846 4.55781 2.68631 4.375C3.30361 3.82969 4.11313 3.5 5.00234 3.5H5.00078ZM6.00096 6C6.00096 5.44844 5.55244 5 5.00078 5C4.44912 5 4.00061 5.44844 4.00061 6C4.00061 6.55156 4.44912 7 5.00078 7C5.55244 7 6.00096 6.55156 6.00096 6Z" fill="white"/>
                                </svg>
                                <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 5.25C0 6.21688 0.807986 7 1.80556 7H10.4722C11.4698 7 12.2778 6.21688 12.2778 5.25V4.9C12.6773 4.9 13 4.58719 13 4.2V2.8C13 2.41281 12.6773 2.1 12.2778 2.1V1.75C12.2778 0.783125 11.4698 0 10.4722 0H1.80556C0.807986 0 0 0.783125 0 1.75V5.25ZM1.80556 5.6C1.60694 5.6 1.44444 5.4425 1.44444 5.25V1.75C1.44444 1.5575 1.60694 1.4 1.80556 1.4H10.4722C10.6708 1.4 10.8333 1.5575 10.8333 1.75V5.25C10.8333 5.4425 10.6708 5.6 10.4722 5.6H1.80556ZM10.1111 4.9V2.1H2.16667V4.9H10.1111Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className="phoneInfoBlock">
                            <div className="phonePhotoUser" />
                            <div className="phoneInfoUser" >
                                <div>
                                    Maksym K...
                                </div>
                                <div>
                                    FaceTime Audio
                                </div>
                            </div>
                        </div>
                        <div className="phoneButtons">
                            <div>
                                <div className="phoneButton">
                                    <div>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.1463 0.001023C1.60263 0.017475 1.06443 0.23246 0.648372 0.64831C-0.183327 1.48043 -0.211744 2.80108 0.545039 3.67807L3.67813 0.544976C3.23963 0.166584 2.68998 -0.015429 2.1463 0.001023ZM8.85393 0.00681315C8.31031 -0.00963884 7.76121 0.172586 7.32293 0.550766L10.4552 3.68303C11.212 2.80647 11.1832 1.4858 10.351 0.6541C9.93498 0.238038 9.39755 0.0232694 8.85393 0.00681315ZM5.5026 1.26997C2.93131 1.26997 0.846774 3.35451 0.846774 5.92579C0.846774 7.16684 1.33572 8.29123 2.12729 9.12585L1.39403 9.85911C1.28346 9.96527 1.23892 10.1229 1.27759 10.2712C1.31626 10.4196 1.43209 10.5354 1.58042 10.5741C1.72874 10.6127 1.88639 10.5682 1.99255 10.4576L2.76466 9.68551C3.53364 10.2465 4.47787 10.5816 5.5026 10.5816C6.52734 10.5816 7.47156 10.2465 8.24054 9.68551L9.01266 10.4576C9.11882 10.5682 9.27646 10.6127 9.42479 10.5741C9.57311 10.5354 9.68895 10.4196 9.72761 10.2712C9.76628 10.1229 9.72174 9.96527 9.61117 9.85911L8.87791 9.12585C9.66948 8.29124 10.1584 7.16684 10.1584 5.9258C10.1584 3.35451 8.07388 1.26997 5.5026 1.26997ZM5.50012 2.53974H5.50508C5.73745 2.53974 5.92585 2.72772 5.92585 2.96051V6.34905H3.38383C3.15104 6.34905 2.96306 6.16106 2.96306 5.92827V5.92331C2.96306 5.69094 3.15104 5.50254 3.38383 5.50254H5.07934V2.96051C5.07934 2.72772 5.26733 2.53974 5.50012 2.53974Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className="phoneButtonName">
                                        Remind Me
                                    </div>
                                </div>
                                <div className="phoneButton">
                                    <div>
                                        <svg width="13" height="11" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 4.64286C12 7.20759 9.31422 9.28571 6.00035 9.28571C5.13087 9.28571 4.30591 9.14286 3.56064 8.88616C3.28175 9.08036 2.82709 9.34598 2.28806 9.5692C1.72559 9.80134 1.04829 10 0.375673 10C0.223338 10 0.0874087 9.91295 0.0288184 9.77902C-0.029772 9.64509 0.00303861 9.4933 0.108501 9.39063L0.115532 9.38393C0.122563 9.37723 0.131937 9.3683 0.145999 9.35268C0.171779 9.32589 0.21162 9.28348 0.260836 9.22545C0.356924 9.11384 0.485823 8.94866 0.617066 8.7433C0.851427 8.37277 1.07407 7.88616 1.1186 7.33929C0.415515 6.58036 0.000695001 5.64955 0.000695001 4.64286C0.000695001 2.07812 2.68648 0 6.00035 0C9.31422 0 12 2.07812 12 4.64286Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className="phoneButtonName">
                                        Message
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="phoneButtonsPart">
                                    <div className="phoneButton">
                                        <div className="phoneButtonFull phoneButtonDecline">
                                            <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.8181 9.16321C18.4112 9.409 19.0922 9.17448 19.4101 8.6175L20.8533 6.09194C21.1374 5.59134 21.054 4.96221 20.6481 4.55631C15.0693 -1.02247 6.02242 -1.02247 0.443637 4.55631C0.0377433 4.96221 -0.0456906 5.59134 0.238435 6.09194L1.68161 8.6175C1.99956 9.17448 2.68056 9.409 3.27362 9.1632L6.34037 7.90042C6.86126 7.6862 7.18147 7.15403 7.12284 6.5948L6.92215 4.5721C9.26054 3.73551 11.8312 3.73551 14.1696 4.5721L13.9667 6.59255C13.9103 7.15403 14.2282 7.68395 14.7491 7.89817L17.8159 9.16095L17.8181 9.16321Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className="phoneButtonName">
                                            Decline
                                        </div>
                                    </div>
                                </div>
                                <div className="phoneButton" onClick={stateVideo}>
                                    <div className="phoneButtonFull phoneButtonAccept">
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.25866 0.785214C5.01311 0.19206 4.36574 -0.123651 3.74708 0.0453657L0.940755 0.810725C0.385869 0.963797 -2.56614e-07 1.46766 -3.56979e-07 2.04168C-1.73644e-06 9.93127 6.39713 16.3284 14.2867 16.3284C14.8607 16.3284 15.3646 15.9425 15.5177 15.3876L16.283 12.5813C16.452 11.9627 16.1363 11.3153 15.5432 11.0697L12.4817 9.79414C11.9619 9.57729 11.3592 9.72717 11.0052 10.1641L9.71688 11.7362C7.47183 10.6743 5.6541 8.85658 4.59216 6.61152L6.16434 5.32635C6.60123 4.96918 6.75111 4.36965 6.53426 3.84984L5.25866 0.788403L5.25866 0.785214Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className="phoneButtonName">
                                        Accept
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="promptPhon"></div>
                </div>
            </div>
            {state && <CIntroduceSpeech close={stateVideo} />}
        </>
    )
})