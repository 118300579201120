import { memo, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import ImgProject1 from '../../../img/imgProject1.png'
import ImgProject3 from '../../../img/imgProject3.png'
import { Footer } from '../../../components/Footer'
import { SolarSystem } from '../../../components/SolarSystem'
import { ColorProjec } from '../../../components/ColorProjec'
import { StructureProjec } from '../../../components/StructureProjec'
import { ProjectButtonLink } from '../../../components/ProjectButtonLink'
import { IntroduceProjectBlock } from '../../../components/IntroduceProjectBlock'
import { ProjectContentBlockLeft } from '../../../components/ProjectContentBlockLeft'
import { ProjectContentBlockRight } from '../../../components/ProjectContentBlockRight'
import { faCompassDrafting, faGlobe, faMicrochip, faFilePowerpoint, faCode} from '@fortawesome/free-solid-svg-icons'
import { faGithub} from '@fortawesome/free-brands-svg-icons'
import { actionSpecificProject } from '../../../actions/project'

const headlinesProject = {
    1:'“The main essence of the project...”',
    2:'“technologies that have been used...”',
    3:'“Interesting solved problems...”'
} 

const Project = memo(({data={}, requestData}) => {
    const { id } = useParams();
    useEffect(
        () => {
            requestData(id)
        },
        [id]
    )
    const {headline, icon, introduce, newProject, technologies, mainImageId, projectimagesSet, codeproject, descriptionproject, technologiesproject, colorsprojectSet} = data
    return(
        <>
            <IntroduceProjectBlock headline={headline} icon={icon} introduce={introduce} mainImg={mainImageId?.imageId.link}/>
            <ProjectContentBlockLeft 
                                imgContent={<img src={ImgProject1} className="ImgProject1" />} 
                                category='01 Description' 
                                icon={faCompassDrafting} 
                                headline={headlinesProject[1]} 
                                readingTime={descriptionproject?.readingTime} 
                                textContent={descriptionproject?.text} 
                                ButtonLink={<ProjectButtonLink icon={faGlobe} text={`Let's watch the live version `} link={descriptionproject?.link}/>} 
            />
            <ProjectContentBlockRight
                                imgContent={<SolarSystem technologies={technologies} />} 
                                category='02 technologies' 
                                icon={faMicrochip} 
                                headline={headlinesProject[2]} 
                                readingTime={technologiesproject?.readingTime} 
                                textContent={technologiesproject?.text} 
                                ButtonLink={<ProjectButtonLink icon={faFilePowerpoint} text={`Let's watch the live version `} link={technologiesproject?.link}/>} 
            />
            <ColorProjec category='03 color' colors={colorsprojectSet} />
            <StructureProjec category='04 structure' imgs={projectimagesSet} />
            <ProjectContentBlockRight
                                imgContent={<img src={ImgProject3} className="ImgProject1" />} 
                                category='05 code' 
                                icon={faCode} 
                                headline={headlinesProject[3]} 
                                readingTime={codeproject?.readingTime} 
                                textContent={codeproject?.text} 
                                ButtonLink={<ProjectButtonLink icon={faGithub} text={`Let's watch the live version `} link={codeproject?.link}/>} 
            />
            <Footer />
        </>
    )
})

export const CProject = connect(store => (({data: store?.promise?.specificProject?.payload})),{requestData: (x) => actionSpecificProject(x)})(Project)